import Slider from "react-slick";


export const Clients = (props) => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div id='clients' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Conoce a nuestros clientes</h2>
        </div>
      </div>
      <div className='slidercontainer'>
        <Slider {...settings}>
          {props.data
            ? props.data.map((d, i) => (
                <div key={i} className='client'>
                  <img src={d.logo} alt='...' className='client-img' />
                  <div className='caption'>
                    <h4>{d.name}</h4>
                  </div>
                </div>
              ))
            : <p>Loading</p>}
        </Slider>
      </div>
    </div>
  )
}
