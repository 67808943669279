export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            <img src="img/logo/logo.svg" alt='logo' />
            BCAA
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll'>
                Sobre Nosotros
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Servicios
              </a>
            </li>
            {/*
            <li>
            <a href='#portfolio' className='page-scroll'>
            Gallery
            </a>
            </li>
            <li>
            <a href='#testimonials' className='page-scroll'>
            Testimonials
            </a>
          </li> */}
            <li>
              <a href='#team' className='page-scroll'>
                Equipo
              </a>
            </li>
          <li>
            <a href='#clients' className='page-scroll'>
              Clientes
            </a>
          </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
