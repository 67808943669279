import { useState, useRef } from 'react'
import emailjs from 'emailjs-com'
import toast from 'react-hot-toast'

export const Contact = (props) => {

  const [{ name, email, message }, setState] = useState('')

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const form = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_q5b0j5b', 'template_ouwyrxb', form.current, 'DZkflKDpxJSGrWG3m'
        )
        .then(
          (result) => {
            console.log(result.text)
            toast.success('Mensaje enviado correctamente!')
          },
          (error) => {
            console.log(error.text)
            toast.error('Error al enviar el mensaje')
          }
          )
      e.target.reset()
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Ponte en contacto</h2>
                <p>
                  Por favor llena el formulario de abajo para enviarnos un email y ponernos en contacto contigo lo antes posible.
                </p>
              </div>
              <form ref={form} name='sentMessage' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nombre'
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensaje'
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Enviar
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Información de Contacto</h3>
              <div>
                <span>
                  <i className='fa fa-map-marker'></i> Dirección
                </span>
                {props.data ? props.data.address.map( (t,index) => <p key={index}>{t}</p> ) : 'loading'}
              </div>
            </div>
            <div className='contact-item'>
              <div>
                <span>
                  <i className='fa fa-phone'></i> Teléfonos
                </span>
                {props.data ? props.data.phone.map( (t,index) => <p key={index}>{t}</p> ) : 'loading'}
              </div>
            </div>
            <div className='contact-item'>
              <div>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>
                {props.data ? props.data.email : 'loading'}
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            
            {/* <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}

          </div>
        </div>
      </div>

      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 Belisario Consultor Actuarial y Asociados. Design by{' '}
            <a href='mailto:gabrielbelisariof@gmail.com' rel='nofollow'>
              ABTL
            </a>
          </p>
        </div>
      </div>

    </div>
  )
}
